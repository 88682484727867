<template>
  <v-container>
    <v-row v-for="(lp, index) in form" :key="index" style="background-color: whitesmoke; border-radius: 5px; margin-bottom: 5px; margin: 5px;">
      <v-col :cols="cols" :sm="cols" :md="cols" v-for="dts in dt" :key="dts.label">
        <v-text-field
          v-if="dts.is_text_field"
          :label=dts.label
          color="black"
          :type=dts.type
            :disabled="data_id == `create` ? dts.disabled : disabled_content"
          v-model="lp[dts.attribute]"
          @input="changeContent"
        ></v-text-field>

        <v-textarea
          v-if="dts.is_textarea"
          :label=dts.label
          color="black"
            :disabled="data_id == `create` ? dts.disabled : disabled_content"
          v-model="lp[dts.attribute]"
          @input="changeContent"
        ></v-textarea>

        <div class="mt-3 mb-3" v-if="dts.is_editor">
          <p>{{dts.label}}</p>
          <ckeditor 
            :read-only="data_id == `create` ? dts.disabled : disabled_content"
            v-model="lp[dts.attribute]" 
            :config="editorConfig"
            @input="changeContent"
          >
          </ckeditor>

          <v-autocomplete
            v-if="dts.is_select"
            :items="(dts.options) ? dts.options : null"
            :disabled="data_id == `create` ? dts.disabled : disabled_content"
            v-model=lp[dts.attribute]
            :item-text="(dts.options) ? dts.options.text : null"
            :item-value="(dts.options) ? dts.options.value : null"
            :multiple=dts.is_multiple
            :chips=dts.is_multiple
            :label=dts.label
            color="black"
            item-color="black"
            @change="changeContent"
          ></v-autocomplete>
        </div>

        <v-switch
          v-if="dts.is_switch"
          v-model="lp[dts.attribute]"
          :disabled="data_id == `create` ? dts.disabled : disabled_content"
          :label=dts.label
          color="black"
    ></v-switch>
      </v-col>
    </v-row>

    <v-row style="margin-bottom: 5px; margin: 5px;">
      <v-btn @click="add" x-small dark color="#F78223" :disabled="disabled_content" class="mr-2">
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
      <v-btn @click="minus" x-small dark color="#F78223" :disabled="disabled_content">
        <v-icon dark> mdi-minus </v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
  import Mixins from '@/mixins/mixin'

  export default {
    mixins: [Mixins],
    props: [`dt`, `disabled_content`, `val`, `data_id`],
    data () {
      return {
        form: [],
        cols: 12,
      }
    },
    async created () {
      let ckeditor = Object.entries(this.dt.filter(fdt => fdt.is_editor)).length;
      this.cols = ckeditor > 0 ? this.cols : this.dt.length <= 4 ? 12/this.dt.length : this.cols;
      if (this.val.value && this.val.value.length > 0) {
        this.form = this.val.value;
      } else {
        let obj = await this.emptyForm();
        this.form.push(obj);
      }
    },
    methods: {
      emptyForm() {
        let obj = {};
        this.dt.map(data =>  { obj[data.attribute] = null });
        return obj;
      },
      async add () {
        if (this.form.length < 20) {
          let obj = await this.emptyForm();
          this.form.push(obj);
        }
      },
      minus () {
        if (this.form.length > 1) {
          this.form.splice(this.form.length-1, 1);
        }
      },
      changeContent() {
        this.val.value = this.form;
      }
    },
  }
</script>

<style scoped>
  .tableview {
    padding: 20px 30px;
  }
</style>
