<template>
  <div class="tableview">
    <div class="cb all mt-5 pt-5" v-if="loading_page">
      <v-progress-circular
        :size="50"
        color="black"
        indeterminate
      ></v-progress-circular>
    </div>
    
    <v-card v-if="!loading_page">
      <v-card>
        <v-card-title>
          <v-icon @click="$router.go(-1)">mdi-arrow-left</v-icon>
          <v-breadcrumbs :items="items_breadcrumbs" light>
            <template v-slot:divider>
              <v-icon>mdi-forward</v-icon>
            </template>
          </v-breadcrumbs>
          
          <v-spacer></v-spacer>
          
          <!-- button edit -->
          <v-tooltip bottom :disabled="page_button_hover == null ? true : false" v-if="btn_edit && field.length > 0">
            <template v-slot:activator="{ on, attrs }">
              <!-- v-if="btn_edit" -->
              <v-btn
                v-bind="attrs"
                v-on="on"
                v-if="page_button_setting ? page_button_setting.show_detail_edit : false"
                depressed
                class="mb-2 mr-2 btn-border"
                @click="edit"
              >
                <v-icon
                  left
                  dark
                >
                  mdi-content-save-edit-outline
                </v-icon>
                {{page_button_name.name_detail_edit}}
              </v-btn>
            </template>
            <span v-if="page_button_hover != null">{{ page_button_hover.hover_detail_edit }}</span>
          </v-tooltip>

          <!-- button cancel -->
          <v-btn
            v-if="btn_cancel"
            depressed
            class="mb-2 mr-2 btn-border"
            :disabled="(!disabled_cancel) ? false : true"
            @click="cancel"
          >
            <v-icon
              left
              dark
            >
              mdi-close
            </v-icon>
            Cancel
          </v-btn>
          &nbsp;

          <!-- button save -->
          <v-tooltip bottom v-if="field.length > 0" :disabled="page_button_hover == null ? true : false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                v-if="page_button_setting ? page_button_setting.show_detail_save : false"
                depressed
                class="mb-2 mr-2 btn-border"
                @click="save(false)"
                :disabled="(!disabled_save) ? false : true"
              >
                <v-icon
                  left
                  dark
                >
                  mdi-content-save
                </v-icon>
                {{page_button_name.name_detail_save}}
              </v-btn>
              <v-btn
                v-bind="attrs"
                v-on="on"
                v-if="page_button_setting ? page_button_setting.show_detail_save : false"
                depressed
                class="mb-2 mr-2 btn-border"
                @click="save(true)"
                :disabled="(!disabled_save) ? false : true"
              >
                <v-icon
                  left
                  dark
                >
                  mdi-content-save
                </v-icon>
                {{page_button_name.name_detail_save}} and continue edit
              </v-btn>
            </template>
            <span v-if="page_button_hover != null">{{ page_button_hover.hover_detail_save }}</span>
          </v-tooltip>

        </v-card-title>

        <v-divider class="mx-4"></v-divider>

        <v-container class="pb-5" v-if="!new_loading">
          <v-row class="pb-5">
            <v-col cols="12" sm="12" md="12" v-if="data || field.length > 0">
              <v-row v-for="dt in field" :key="dt.label">
                <v-col cols="12" sm="6" md="6" v-if="!dt.section_name">
                  <FormComponent
                    :key="componentKey"
                    :dt=dt
                    :disabled_content=disabled_content
                    :btn_file_edit=btn_file_edit
                    :data_id=data_id
                    :editorConfig=editorConfig
                    :date_picker_menu=date_picker_menu
                    :url_server=url_server
                    @getFile="getFile"
                    @deleteImage="deleteImage"
                  />
                </v-col>
              </v-row>

              <div v-if="Object.entries(field_with_section).length > 0">
                <v-row v-for="(fws, index) in field_with_section" :key="index">
                  <v-col cols="12" sm="12" md="12" class="mt-2">
                    <v-card elevation="2" outlined class="mx-auto" style="padding: 40px">
                      <v-card-title style="padding: 0 !important"><b>Section {{index}}</b></v-card-title>
                      <div v-for="dt in fws" :key="dt.label">
                        <FormComponent
                          :key="componentKey"
                          :dt=dt
                          :disabled_content=disabled_content
                          :btn_file_edit=btn_file_edit
                          :data_id=data_id
                          :editorConfig=editorConfig
                          :date_picker_menu=date_picker_menu
                          :url_server=url_server
                          @getFile="getFile"
                          @deleteImage="deleteImage"
                        />
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-col>

            <!-- <v-col cols="12" sm="6" md="6"></v-col> -->
          </v-row>

          <!-- CHILD TABLE -->
          <!-- <div>
            <v-row v-for="ch in child_data" :key="ch.key">
              <v-col cols="12" sm="12" md="12">
                <ChildForm
                  :child_data=ch
                  :page_button_setting=page_button_setting
                  :page_button_hover=page_button_hover
                  :page_button_name=page_button_name
                  @createChild="createChild"
                  @deleteChild="deleteChild"
                />
              </v-col> 
            </v-row>
          </div> -->

        </v-container>

        <v-container class="pb-5" v-else>
          <v-row class="pb-5">
            <v-col cols="6" sm="6" md="6">
              <v-skeleton-loader
                v-for="i in field.length"
                :key="i"
                v-bind="attrs"
                type="card-heading, list-item, divider"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>

      </v-card>
    </v-card>
    
    <v-snackbar v-model="snackbar">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn depressed class="btn-border" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import global from '@/global.js'
  // import axios from 'axios'

  // import CustomSwitch from '@/components/Form/CustomSwitch.vue'
  // import ChildForm from '@/components/ChildTableView.vue' 
  import FormComponent from '@/components/Form/FormComponent.vue'
  import Mixins from '@/mixins/mixin'

  export default {
    mixins: [Mixins],
    components: {
      FormComponent,  /* ChildForm, CustomSwitch */
    },
    data () {
      return {
        Model: null,
        url_server: global.url_server,
        page_name: null,
        page_path: null,
        componentKey: 0,

        // ini gan belum
        new_loading: false,
        attrs: {
          class: 'mb-6',
          boilerplate: true,
        },

        switch_value: false,
        page_button_setting: null,
        page_button_hover: null,
        page_button_name: null,
        page_info: null,
        
        data: null,
        field: [],
        field_with_section: [],
        child_data: null,
        data_id: null,
        is_create: false,

        disabled_cancel: false,
        disabled_save: true,
        disabled_content: true,
        
        required_attribute: [],
        logic_boolean: [],
        logic_file: false,
        arr_logic_file: [],

        files: [],

        snackbar: false,
        snackbar_text: null,
        
        btn_edit: true,
        btn_cancel: false,
        btn_file_edit: false,
        
        loading_page: true,
        dialog_delete: false,
        options: {},
        search: '',
        selected: [],

        date_picker_menu: false,
        custom_component: true,

        tab: null,
        items_breadcrumbs: [],
      }
    },
    created : async function () {
      this.page_name = this.$route.name;
      this.page_path = `/${this.$route.path.split(`/`)[1]}`;
      this.data_id = this.$route.params.id;
      this.btn_edit = this.data_id  == `create`? false : true;
      this.disabled_save = this.data_id  == `create`? false : true;
      this.disabled_content = this.data_id  == `create` ? false : true;
      this.btn_file_edit = this.data_id  == `create` ? true : this.btn_cancel;
    }, 
    mounted : async function () {
      this.items_breadcrumbs = await this.getItemBreadCrumbs(this.page_name);
      this.Model = await this.getAuth(this.page_path);

      let promise = this.getData()
      let promise_get_menu_info = this.getMenuInfo()
      Promise.all([promise, promise_get_menu_info]).then(async () => {
        this.loading_page = false
        
      });
    },
    methods: {
      showMessage (snackbar, msg) {
        this.snackbar = snackbar;
        this.snackbar_text = msg;
      },

      getMenuInfo : async function () {
        let get_menu_info = await this.getMenuInfoMixin(this.page_path);
        if (get_menu_info.status == 400) {
          this.showMessage(true, get_menu_info.msg);
        } else if (get_menu_info.status == 200) {
          let gmi = get_menu_info.data;
          this.page_button_setting = gmi.button_setting ? gmi.button_setting : null;
          this.page_button_hover = gmi.button_hover ? gmi.button_hover : null;
          this.page_button_name = gmi.button_name ? gmi.button_name : null;
          this.page_info = gmi.menu_info;
        }
      },

      cancel : async function () {
        this.btn_edit = true;
        this.btn_cancel = false;
        this.btn_file_edit = false;
        this.disabled_content = true;
        this.custom_component = false;
        setTimeout(() => {
          this.custom_component = true;
        }, 50);
        this.disabled_save = true;
        this.componentKey += 1;
        this.getData();
      },

      edit : async function () {
        this.btn_edit = false;
        this.btn_cancel = true;
        this.btn_file_edit = true;
        this.custom_component = false;
        setTimeout(() => {
          this.custom_component = true;
        }, 50);
        this.componentKey += 1;
        this.disabled_content = false;
        this.disabled_save = false;
      },

      getFile : async function (att, files) {
        this.componentKey += 1;
        this.field.map(data => {
          for (const value of Object.values(data)) {
            if (value == att) {
              data.value = files
            }
          }
        })
      },

      formCheck: function () {
        let obj = {}, flag = 0, attr_required = [];
        let formData = new FormData();

        // create obj
        this.field.map(data => {
          for (const [key, value] of Object.entries(data)) {
            if (key == `attribute`) {
              obj[value] = data.value
            }
          }
        });

        //get file and other attributes
        for (const [att] of Object.entries(obj)) {
          if (this.logic_file) {
            let arr_file = this.arr_logic_file.find(find => find == att);
            if (arr_file && obj[att]) {
              // append data if attribute is file 
              if (obj[att].length > 0) {
                obj[att].map(file => {
                  formData.append(att, file);
                })
              }
            } else {
              let res = Array.isArray(obj[att]) ? JSON.stringify(obj[att]) : obj[att];
              formData.append(att, res);
            }
          }
        }

        //default value for boolean field
        this.logic_boolean.map((att) => {
          obj[att] = obj[att] ? obj[att] : false;
        });

        // check if there any required empty
        this.required_attribute.map((att) => {
          // for empty field
          if (obj[att] == undefined || obj[att] == null || obj[att] == '') {
            // for update form when file is not updated because using the old one
            if (this.data_id != `create` && this.field.find(fm => fm.attribute == att).files.length == 0) {
              flag++;
              attr_required.push(att);
            }

            //create required
            if (this.data_id == `create`) {
              flag++;
              attr_required.push(att);
            }
          }
        });
        let result = this.logic_file ? formData : obj
        return { flag, result, attr_required }
      },

      save : async function (is_edit) {
        this.disabled_content = true;
        this.disabled_save = true;
        this.disabled_cancel = true;
        this.new_loading = true
        this.snackbar = true;
        this.snackbar_text = `Please wait a moment..`;
        // let headers = this.logic_file ? { headers: { 'Content-Type': 'multipart/form-data' }} : null;
        let check = this.formCheck();
        // console.log(check.result)
        if (check.flag == 0) {
          // using append if there're file field
          let res = this.data_id == `create` ? await this.Model.create(check.result) : 
            await this.Model.update(this.data._id, check.result);
          if (res.data.status == 200) {
            this.snackbar = true;
            this.snackbar_text = res.data.message;
            setTimeout(() => {
              this.redirectSave(res.data.data._id, is_edit);
            }, 2000)
          } else {
            this.snackbar = true;
            this.snackbar_text = res.data.message;
            this.disabled_content = false;
            this.disabled_cancel = false;
            this.disabled_save = false;
            this.new_loading = false
          }
        } else {
          this.snackbar = true;
          this.snackbar_text = `Please fill the required field ${check.attr_required.join(', ')}`;
          this.disabled_content = false;
          this.disabled_cancel = false;
          this.disabled_save = false;
          this.new_loading = false
        }
      },

      logicSection: async function (field) {
        let obj = {};
        let long_section_arr = field.filter(f => f.section_name).map(ffs => ffs.section_name);
        let section_arr = [...new Set(long_section_arr)];
        if (section_arr.length > 0) {
          section_arr.map(sa => {
            obj[sa] = field.filter(ff => ff.section_name == sa);
          })
        }
        return obj;
      },

      logicSwitch(f) {
        if (f.is_switch) {
          f.value = f.value ==`false` || !f.value? false : true;
          this.logic_boolean.push(f.attribute)
        }
      },

      logicRequired(f) {
        if (f.required && !f.is_switch) {
          this.required_attribute.push(f.attribute)
        }
      },

      async logicFile(f) {
        if (f.is_file && this.data_id != `create`) {
          /* get image using array buffer but takes long time */

          /* let files_name = [];
          await f.files.map(async file => {
            let image = await fetch(file.base64);
            let blob = await image.blob();
            files_name.push(new File([blob], file.file_name, {type: file.file_type}))
          }) 
           f.value = files_name;
          */
          this.logic_file = true
          this.arr_logic_file.push(f.attribute)
        } else if (f.is_file && this.data_id == `create`) {
          f.file_name = null
          this.logic_file = true
          this.arr_logic_file.push(f.attribute)
        }
      },

      getData : async function () {
        let res = this.data_id == `create` ? await this.Model.getField() : 
          await this.Model.getDetail(this.data_id);
        if (res.data.status == 400) {
          this.data = null;
          this.$router.push({ name: `PageNotFound` });
          console.log(`Error when get data or empty data`);
        } else if (res.data.data.status == 201) {
          this.data = null;
          this.field = [];
          this.snackbar = true;
          this.loading_page = true
          this.snackbar_text = `Something wrong, please contact our admin`;
        }  else if (res.data.status == 200) {
          let data = this.data_id == `create` ? res.data.data.data : res.data.data.content;
          if (this.data_id == `create`) {
            this.field = data
          } else {
            this.data = res.data.data;
            this.field = data;
          }

          /* SETTING FOR REQUIRED FIELD */
          this.field.map(f => {
            // get data for boolean field
            this.logicSwitch(f);

            // get data for required logic
            this.logicRequired(f);
            
            // get data for file field
            this.logicFile(f);

          });

          // get data for section
          this.field_with_section = await this.logicSection(this.field);

          /* SETTING BREADCRUMBS TABLE */
          if (this.data_id != `create` && this.items_breadcrumbs.length < 2) {
            this.items_breadcrumbs.push({
              text: this.data.name.length < 35 ? this.data.name.slice(0, 35) : `${this.data.name.slice(0, 35)}...`,
              disabled: true
            })
          }
          /* 
          

          // SETTING EDIT IN CHILD TABLE
          if (Object.getOwnPropertyNames(this.data).find(f => f == `child`)) {
            this.child_data = this.data.child;
            this.child_data.is_edit = false;
          } */

        }
      },

      redirectSave(value, is_edit) {
        let id = value ? value : null ;
        let route_push = is_edit && id ? { path: `${this.page_path}/${id}`, param: { id } } : { path: this.items_breadcrumbs[0].href };
        if (this.data_id == `create`) {
          this.$router.push(route_push);
          if (is_edit) location.reload();
        } else {
          if (is_edit) {
            location.reload();
          } else {
            this.$router.push(route_push);
          }
        }
      },

      deleteImage: async function (id) {
        let Fileupload = await this.getAuth(`/file-upload`), body = {id};
        await Fileupload.delete(body);
        location.reload();
      }

      /* createChild: async function (data) {
        let Model = (data.setting.page_name == `MenuDetail`) ? Menu : 
          (data.setting.page_name == `UserDetail`) ? User : 
          (data.setting.page_name == `MerchantListDetail`) ? MerchantList : 
          (data.setting.page_name == `MarketplaceDetail`) ? Marketplace : null;
        
        let obj = {
          _id: data.setting.parent_id,
          data: data.data
        }

        let res = await Model.CreateChildData(obj);
        if (res.data.status == 200) {
          this.disabled_save = true;
          this.snackbar = true;
          this.snackbar_text = res.data.message;
          this.disabled_content = true;

          setTimeout(() => {
            this.$router.go()
          }, 2000)
        } else {
          this.snackbar = true;
          this.snackbar_text = res.data.message;
        }
      },
      
      deleteChild: async function (data) {
        let Model = (data.setting.page_name == `MenuDetail`) ? Menu : 
          (data.setting.page_name == `UserDetail`) ? User : 
          (data.setting.page_name == `MerchantListDetail`) ? MerchantList : 
          (data.setting.page_name == `MarketplaceDetail`) ? Marketplace : null;
        
        let obj = {
          _id: data.setting.parent_id,
          data: data.data
        }
        
        let res = await Model.DeleteChildData(obj);
        if (res.data.status == 200) {
          this.disabled_save = true;
          this.snackbar = true;
          this.snackbar_text = res.data.message;
          this.disabled_content = true;

          setTimeout(() => {
            this.$router.go()
          }, 2000)
        } else {
          this.disabled_save = false;
          this.snackbar = true;
          this.snackbar_text = res.data.message;
        }
      } */
    }
  }
</script>

<style>
  .tableview {
    padding: 20px 30px;
  }
  .v-application a {
    color: black !important;
    font-weight: 600;
  }
  
</style>
